import axios from "axios";
const TOKEN = "Bearer 5bb4cb5e7b93858d18c0e18f85fb1376";

export default {
  async getMateriali(projectID: string) {
    const data = new FormData();
    data.append("where[projects_billing_items_project]", projectID.toString());

    const response = await axios.post("/search/projects_billing_items", data, {
      headers: {
        Authorization: TOKEN,
      },
    });
    //console.log(response);
    return response.data.data;
  },
  async getEquipments(projectID: string) {
    const data = new FormData();
    data.append("where[equipments_project_id]", projectID.toString());

    const response = await axios.post("/search/equipments", data, {
      headers: {
        Authorization: TOKEN,
      },
    });
    //console.log(response);
    return response.data.data;
  },
};
