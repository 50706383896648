<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button fill="clear" size="small" @click="closeModal">
            <ion-icon :icon="arrowBackOutline" class="icon_close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Nuovo intervento</ion-title>
        <!--         <ion-buttons slot="end">
          <ion-button fill="clear" size="small" @click="closeModal">
            <ion-icon :icon="close" class="icon_close"></ion-icon>
          </ion-button>
        </ion-buttons> -->
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-grid>
        <form @submit.prevent="handleInterventoCreation()">
          <ion-list lines="none">
            <ion-row>
              <ion-col size="12">
                <ion-item>
                  <div class="date_input_container">
                    <div class="label">
                      Data
                      <ion-text color="danger"><strong>*</strong></ion-text>
                    </div>
                    <ion-datetime
                      name="date"
                      display-format="DD/MM/YYYY"
                      picker-format="DD/MM/YYYY"
                      min="2021"
                      max="2099"
                      slot="end"
                      doneText="OK"
                      cancelText="Annulla"
                      v-model="intervento.data"
                    ></ion-datetime>
                  </div>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col size="12">
                <ion-item>
                  <div class="date_input_container">
                    <div class="label">
                      Ora inizio
                      <ion-text color="danger"><strong>*</strong></ion-text>
                    </div>
                    <ion-datetime
                      name="start_time"
                      display-format="HH:mm"
                      picker-format="HH:mm"
                      value="1990-02-19T07:43Z"
                      slot="end"
                      doneText="OK"
                      cancelText="Annulla"
                      v-model="intervento.ora_inizio"
                    ></ion-datetime>
                  </div>
                </ion-item>
              </ion-col>
              <ion-col size="12">
                <ion-item>
                  <div class="date_input_container">
                    <div class="label">
                      Ora fine
                      <ion-text color="danger"><strong>*</strong></ion-text>
                    </div>
                    <ion-datetime
                      name="end_time"
                      display-format="HH:mm"
                      picker-format="HH:mm"
                      :value="intervento.ora_fine"
                      slot="end"
                      doneText="OK"
                      cancelText="Annulla"
                      v-model="intervento.ora_fine"
                    ></ion-datetime>
                  </div>
                </ion-item>
              </ion-col>
            </ion-row>

            <!--             <ion-row>
              <ion-col size="12">
                <ion-item @click="openTecniciSelect()">
                  <ion-label position="stacked">
                    Tecnici
                    <ion-text color="danger"><strong>*</strong></ion-text>
                  </ion-label>
                  <div class="fake_input">
                    <div class="placeholder">Seleziona</div>
                    <ion-icon
                      :icon="chevronForward"
                      class="fake_arrow"
                    ></ion-icon>
                  </div>
                </ion-item>
              </ion-col>
            </ion-row> -->

            <ion-row>
              <ion-col size="12">
                <ion-item>
                  <ion-label position="stacked">
                    Tecnici
                    <ion-text color="danger"><strong>*</strong></ion-text>
                  </ion-label>
                  <ion-select
                    name="tecnici"
                    multiple="true"
                    cancel-text="Annulla"
                    ok-text="Conferma"
                    v-model="intervento.tecnici"
                  >
                    <ion-select-option
                      v-for="tecnico in tecnici"
                      :key="tecnico.users_id"
                      :value="tecnico.users_id"
                    >
                      {{
                        tecnico.users_first_name + " " + tecnico.users_last_name
                      }}
                    </ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="stacked">
                    Tempo di viaggio (min)
                  </ion-label>
                  <ion-input
                    type="number"
                    placeholder="35"
                    v-model="intervento.tempo_viaggio"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="stacked"> Ore da fatturare </ion-label>
                  <ion-input
                    type="number"
                    placeholder="2"
                    step="any"
                    v-model="intervento.ore_fatturabili"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="stacked">
                    Stato lavori
                    <ion-text color="danger"><strong>*</strong></ion-text>
                  </ion-label>
                  <ion-select
                    cancelText="Annulla"
                    v-model="intervento.stato_lavori"
                  >
                    <ion-select-option value="1">
                      Lavoro terminato
                    </ion-select-option>
                    <ion-select-option value="2">
                      Lavoro da terminare
                    </ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="stacked">Descrizione</ion-label>
                  <ion-textarea
                    type="text"
                    placeholder="Inserisci descrizione..."
                    rows="4"
                    v-model="intervento.descrizione"
                  >
                  </ion-textarea>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col size="12" style="margin-bottom: 10px">
                <ion-item>
                  <div class="titolo_input_materiale">Materiale utilizzato</div>
                  <div
                    v-for="(
                      materiale, index
                    ) in intervento.materiale_utilizzato"
                    :key="index"
                    class="input_materiale_utilizzato"
                  >
                    <div class="field_materiale_utilizzato">
                      <ion-label position="stacked">Codice</ion-label>
                      <ion-input
                        placeholder="Inserisci il codice"
                        v-model="materiale.key"
                      >
                      </ion-input>
                      <ion-label position="stacked">Descrizione</ion-label>
                      <ion-textarea
                        type="text"
                        rows="3"
                        placeholder="Inserisci la descrizione"
                        v-model="materiale.value"
                      >
                      </ion-textarea>
                    </div>
                    <div class="delete_materiale_utilizzato">
                      <ion-icon
                        :icon="trash"
                        class="remove_materiale_utilizzato"
                        @click="handleRemoveMaterialeUtilizzato(index)"
                      ></ion-icon>
                      <ion-text color="danger">Elimina</ion-text>
                    </div>
                  </div>
                </ion-item>
              </ion-col>
              <ion-col size="12" class="col_btn_materiali">
                <div @click="addMaterialeUtilizzato()" class="btn_materiali">
                  Aggiungi materiale
                </div>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col size="12" style="margin-bottom: 10px">
                <ion-item>
                  <div class="titolo_input_materiale">Materiale ritirato</div>
                  <div
                    v-for="(materiale, index) in intervento.materiale_ritirato"
                    :key="index"
                    class="input_materiale_ritirato"
                  >
                    <div class="field_materiale_ritirato">
                      <ion-label position="stacked">Codice</ion-label>
                      <ion-input
                        placeholder="Inserisci il codice"
                        v-model="materiale.key"
                      >
                      </ion-input>
                      <ion-label position="stacked">Descrizione</ion-label>
                      <ion-textarea
                        type="text"
                        rows="3"
                        placeholder="Inserisci la descrizione"
                        v-model="materiale.value"
                      >
                      </ion-textarea>
                    </div>
                    <div class="delete_materiale_ritirato">
                      <ion-icon
                        :icon="trash"
                        class="remove_materiale_ritirato"
                        @click="handleRemoveMaterialeRitirato(index)"
                      ></ion-icon>
                      <ion-text color="danger">Elimina</ion-text>
                    </div>
                  </div>
                </ion-item>
              </ion-col>
              <ion-col size="12" class="col_btn_materiali">
                <div @click="addMaterialeRitirato()" class="btn_materiali">
                  Aggiungi materiale
                </div>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col size="12">
                <ion-button
                  size="default"
                  expand="block"
                  type="submit"
                  class="btn_submit ion-text-capitalize"
                >
                  Salva
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-list>
        </form>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  //IonBackButton,
  IonButton,
  modalController,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonText,
  IonIcon,
  alertController,
} from "@ionic/vue";
import { defineComponent, ref, reactive, onMounted } from "vue";

import { arrowBackOutline, trash, chevronForward } from "ionicons/icons";

//import axios from "axios";

import { getCustomers } from "../services/localbase";
import utils from "../services/utils";
import { openToast } from "../services/toast";

import apiInterventi from "../services/interventi";
import apiTecnici from "../services/tecnici";
import apiTickets from "../services/ticket";

import { useRouter } from "vue-router";

import SelectTecnici from "../components/SelectTecnici.vue";

export default defineComponent({
  name: "Modal2",
  props: {
    cliente: {
      type: String,
      required: true,
    },
    impianto: {
      type: String,
      required: true,
    },
    ticket: {
      type: String,
      default: null,
    },
  },
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    //IonBackButton,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonLabel,
    IonDatetime,
    IonTextarea,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonText,
    IonIcon,
  },
  setup(props, context) {
    const router = useRouter();

    const start_date = new Date();
    const end_hour = new Date();
    const start_hour = new Date(end_hour);
    start_hour.setHours(start_hour.getHours() - 1);

    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id; //set userID in operatore field
    const intervento = reactive({
      operatore: "",
      data: start_date.toISOString(),
      cliente: "",
      impianto: "",
      ora_inizio: start_hour.toISOString(),
      ora_fine: end_hour.toISOString(),
      tecnici: [],
      tempo_viaggio: "",
      descrizione: "",
      stato_lavori: "",
      ore_fatturabili: "",
      materiale_utilizzato: [
        {
          key: "",
          value: "",
        },
      ],
      materiale_ritirato: [
        {
          key: "",
          value: "",
        },
      ],
    });

    /*     console.log("id cliente: ", props.cliente);
    console.log("id impianto: ", props.impianto);
    console.log("id ticket: ", props.ticket); */

    const tecnici = ref([]);
    // const conducente = ref([]);
    // const automezzi = ref([]);

    const customersDB = ref([]);

    const successResponse = ref(false);

    /**
     *
     * Open modal to select tecnici
     *
     */
    async function openTecniciSelect() {
      const modal = await modalController.create({
        component: SelectTecnici,
        componentProps: {
          technicians: intervento.tecnici,
        },
      });
      modal.onDidDismiss().then((detail) => {
        //console.log(detail);
        if (detail.data) {
          //console.log(detail.data.value);
          detail.data.value.forEach((tecnico) => {
            intervento.tecnici = [tecnico, ...intervento.tecnici];
            //intervento.tecnici.push(tecnico);
          });
          //openToast("Foto caricate con successo", "success");
          //devo avere i tecnici selezionati nella modale e qui li assegno al campo intervento_tecnici
        }
      });
      return modal.present();
    }

    /**
     *
     * Add materiale utilizzato
     *
     */
    function addMaterialeUtilizzato() {
      intervento.materiale_utilizzato.push({
        key: "",
        value: "",
      });
    }
    /**
     *
     * Remove single materiale utilizzato
     *
     */
    function removeMaterialeUtilizzato(index) {
      //console.log(index);
      //Se ho inserito più di un elemento allora posso rimuovere
      if (index > 0) {
        intervento.materiale_utilizzato.splice(index, 1);
      }
    }
    /**
     *
     * Handle remove single materiale utilizzato
     *
     */
    async function handleRemoveMaterialeUtilizzato(index) {
      const alert = await alertController.create({
        header: "Conferma azione",
        message: "Sei sicuro di voler eliminare questo materiale utilizzato?",
        backdropDismiss: false,
        buttons: [
          {
            text: "Annulla",
            role: "cancel",
            handler: () => {
              //console.log('Annulla');
            },
          },
          {
            text: "Elimina",
            handler: () => {
              //Call remove function for the clicked materiale
              removeMaterialeUtilizzato(index);
            },
          },
        ],
      });
      return alert.present();
    }

    /**
     *
     * Add materiale ritirato
     *
     */
    function addMaterialeRitirato() {
      intervento.materiale_ritirato.push({
        key: "",
        value: "",
      });
    }
    /**
     *
     * Remove single materiale ritirato
     *
     */
    function removeMaterialeRitirato(index) {
      //console.log(index);
      //If at least one materiale is inserted
      if (index > 0) {
        intervento.materiale_ritirato.splice(index, 1);
      }
    }
    /**
     *
     * Handle remove single materiale ritirato
     *
     */
    async function handleRemoveMaterialeRitirato(index) {
      const alert = await alertController.create({
        header: "Conferma azione",
        message: "Sei sicuro di voler eliminare questo materiale ritirato?",
        backdropDismiss: false,
        buttons: [
          {
            text: "Annulla",
            role: "cancel",
            handler: () => {
              //console.log('Annulla');
            },
          },
          {
            text: "Elimina",
            handler: () => {
              //Call remove function for the clicked materiale
              removeMaterialeRitirato(index);
            },
          },
        ],
      });
      return alert.present();
    }

    /**
     *
     * Format given date (OLD VERSION)
     *
     */
    function dateFormat(date) {
      const dateFormatted = new Date(date);
      return `${dateFormatted.getDate()}/${
        dateFormatted.getMonth() + 1
      }/${dateFormatted.getFullYear()}`;
    }

    /**
     *
     * Close modal after form data submit
     *
     */
    async function closeModalOnSubmit(successResponse, res) {
      if (successResponse.value) {
        //console.log("successResponse ", successResponse.value);
        await modalController.dismiss(res);
      } else {
        //console.log("successResponse ", successResponse.value);
        await modalController.dismiss();
      }
    }

    /**
     *
     * Get clienti from localDB
     *
     */
    async function getClienti() {
      const res = await getCustomers();
      res.forEach((cliente) => {
        customersDB.value.push(cliente);
      });
      //console.log(customersDB.value);
      return customersDB;
    }
    getClienti();

    /**
     *
     * Handle close modal by clicking on Chiudi button
     *
     */
    const closeModal = () => {
      modalController.dismiss();
    };

    /**
     *
     * Create new intervento (required field ?? )
     * Set intervento_operatore = to ID of logged user
     *
     */
    function creaIntervento() {
      intervento.operatore = userID; //set operatore as logged user id

      //If one of the required field is empty show danger toast else send data
      //convert data to ISO format in Date format
      const ora_inizio = new Date(intervento.ora_inizio);
      const ora_fine = new Date(intervento.ora_fine);

      const checkTecniciLoggato = intervento.tecnici.includes(userID);
      if (!checkTecniciLoggato && intervento.tecnici.length != 0) {
        openToast(
          "Non puoi creare un intervento senza essere tra i tecnici selezionati",
          "danger"
        );
      } else if (ora_inizio > ora_fine) {
        openToast(
          "L'ora inizio non può essere maggiore dell'ora fine",
          "danger"
        );
      } else if (
        !intervento.data ||
        !intervento.ora_inizio ||
        !intervento.ora_fine ||
        !intervento.stato_lavori ||
        intervento.tecnici.length == 0
      ) {
        if (intervento.tecnici.length === 0) {
          openToast("I tecnici presenti sono obbligatori", "danger");
        }
        if (!intervento.stato_lavori) {
          openToast("Lo stato dei lavori è obbligatorio", "danger");
        }
      } else {
        const data = new FormData();
        data.append("tickets_reports_technician", intervento.operatore); //logged user
        data.append("tickets_reports_date", utils.dateFormat(intervento.data));
        data.append("tickets_reports_customer_id", props.cliente); //Customer id from "parent"
        data.append("tickets_reports_project_id", props.impianto); //Impianto id from "parent"
        data.append(
          "tickets_reports_start_time",
          utils.timeFormat(intervento.ora_inizio)
        );
        data.append(
          "tickets_reports_end_time",
          utils.timeFormat(intervento.ora_fine)
        );
        intervento.tecnici.forEach((tecnico) => {
          data.append("tickets_reports_tecnici[]", tecnico);
        });
        data.append("tickets_reports_ticket_id", props.ticket); //Ticket id from "parent"
        data.append("tickets_reports_travel_time", intervento.tempo_viaggio);
        data.append(
          "tickets_reports_billable_hours",
          intervento.ore_fatturabili
        );
        data.append("tickets_reports_stato_lavori", intervento.stato_lavori);

        intervento.materiale_utilizzato.forEach((materiale, index) => {
          for (const [key, value] of Object.entries(materiale)) {
            //console.log(`${key}: ${value}`);
            data.append(
              `tickets_reports_used_equipments[${index}][${key}]`,
              value
            );
          }
        });

        intervento.materiale_ritirato.forEach((materiale, index) => {
          for (const [key, value] of Object.entries(materiale)) {
            //console.log(`${key}: ${value}`);
            data.append(
              `tickets_reports_materiali_ritirati[${index}][${key}]`,
              value
            );
          }
        });

        data.append("tickets_reports_description", intervento.descrizione);

        apiInterventi
          .saveIntervento(data)
          .then((response) => {
            //console.log(response);
            const nuovo_intervento = response.data.data[0];
            //se avevo scelto un ticket e avevo messo stato lavori = terminati devo aggiornare lo stato del ticket in Lavoro terminato
            if (
              nuovo_intervento.tickets_reports_ticket_id &&
              nuovo_intervento.tickets_reports_stato_lavori == "1"
            ) {
              apiTickets.changeTicketStatus(
                nuovo_intervento.tickets_reports_ticket_id
              );
            }
            //chiudo modale e mostro e aggiorno gli interventi con il nuovo effettuato
            const res = response.data.data[0];
            successResponse.value = true;
            closeModalOnSubmit(successResponse, res);
          })
          .catch((error) => {
            // handle error
            console.error(error);
            openToast("Errore durante la creazione dell'intervento", "danger");
          })
          .finally(() => {
            //console.log("fine creazione intervento");
          });
      }
    }

    /*
     *
     * Alert to confirm intervento creation
     *
     */
    async function handleInterventoCreation() {
      const alert = await alertController.create({
        header: "Conferma",
        message:
          "I dati inseriti sono tutti inseriti correttamente? Se decidi di salvare non potranno essere modificati. Questa operazione potrà essere effettuata solamente da web.",
        backdropDismiss: false,
        buttons: [
          {
            text: "Annulla",
            role: "cancel",
            handler: () => {
              //console.log("CHIUSUTRA ALERT RICHIESTA RECUPERO PASSWORD...");
            },
          },
          {
            text: "Salva",
            handler: () => {
              //Call logout function (delete localbasedb and redirect to login page)
              creaIntervento();
            },
          },
        ],
      });
      return alert.present();
    }

    onMounted(() => {
      apiTecnici
        .getTecnici()
        .then((response) => {
          tecnici.value = response;
          //conducente.value = response;
        })
        .catch((error) => {
          error.value = true;
          openToast("Errore durante la richiesta dei dati", "danger");
        })
        .finally(() => {
          //console.log("fine richiesta tecnici e conducente");
        });
    });

    return {
      arrowBackOutline,
      intervento,
      creaIntervento,
      closeModal,
      customersDB,
      tecnici,
      userID,
      //new field for figma design
      addMaterialeRitirato,
      removeMaterialeRitirato,
      addMaterialeUtilizzato,
      removeMaterialeUtilizzato,
      trash,
      chevronForward,
      openTecniciSelect,
      handleRemoveMaterialeUtilizzato,
      handleRemoveMaterialeRitirato,
      //Alert pre creazione intervento
      handleInterventoCreation,
    };
  },
});
</script>

<style scoped>
.error_message {
  color: #eb445a;
}
.danger {
  color: #eb445a !important;
}

.example {
  border: 1px solid black;
}

.alert-wrapper {
  width: 90% !important;
}

.icon_close {
  color: #000000;
}

/* rules for figma design */
ion-item {
  --ripple-color: transparent !important;
  --highlight-height: 0;
}

.titolo_input_materiale {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.input_materiale_utilizzato,
.input_materiale_ritirato {
  width: 100%;
  display: flex;
  justify-content: space-around;
  /*align-items: center;
  flex-direction: column;*/
  background-color: #f8f8f8;
  border-radius: 4px;
  padding: 18px;
}

.remove_materiale_utilizzato,
.remove_materiale_ritirato {
  font-size: 20px;
  color: #eb445a;
}

ion-input,
ion-select,
ion-textarea {
  --background: #f8f8f8;
  --placeholder-color: #747474;
  --placeholder-font-style: 10px;
  --padding-start: 24px !important;
  margin-top: 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.col_btn_materiali {
  display: flex;
  justify-content: center;
}
.btn_materiali {
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  padding: 8px;
  border-radius: 4px;
  background: #747474;
  color: #ffffff;
}

/* fake input for tecnici */
.fake_input {
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 4px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
}
.fake_input .placeholder {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #747474;
}
.fake_input .fake_arrow {
  color: #007aff;
}

.input_materiale_utilizzato ion-input,
.input_materiale_utilizzato ion-textarea,
.input_materiale_ritirato ion-input,
.input_materiale_ritirato ion-textarea {
  --background: #ffffff;
  --placeholder-color: #747474;
  --placeholder-font-style: 12px;
  --padding-start: 18px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.field_materiale_utilizzato,
.field_materiale_ritirato {
  width: 100%;
  margin-right: 18px;
}
.delete_materiale_utilizzato,
.delete_materiale_ritirato {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
}
.delete_materiale_utilizzato ion-text,
.delete_materiale_ritirato ion-text {
  font-weight: 500;
  font-size: 8px;
  line-height: 10px;
}

.btn_submit {
  --background: #747474;
  --box-shadow: none;
  --border-radius: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
}

ion-select {
  background: #f8f8f8 !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px 12px;
}

.date_input_container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.date_input_container .label {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px;
}

ion-datetime {
  --placeholder-color: #747474;
  color: #000000 !important;
  background: #f8f8f8 !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px 12px;
  margin-left: 0;
}
</style>
