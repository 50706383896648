<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-icon
            :icon="close"
            class="closeTecniciModal"
            @click="closeModal"
          ></ion-icon>
        </ion-buttons>
        <ion-title color="dark">Tecnici</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-icon
              :icon="close"
              class="closeTecniciModal"
              @click="closeModal"
            ></ion-icon>
          </ion-buttons>
          <ion-title size="large" color="dark">Tecnici</ion-title>
        </ion-toolbar>
      </ion-header>

      <div v-if="loadingTecnici" class="loading_spinner">
        <ion-spinner name="crescent" color="dark"></ion-spinner>
      </div>

      <div v-else>
        <div class="tecnici_container">
          <div
            class="tecnico"
            v-for="tecnico in tecnici"
            :key="tecnico.users_id"
            @click="selectTecnico(tecnico)"
          >
            <span>
              {{ tecnico.users_first_name }} {{ tecnico.users_last_name }}
            </span>
            <ion-icon
              :icon="checkmarkCircle"
              class="selected_tecnico_icon"
              v-show="tecnico.selected"
            ></ion-icon>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  modalController,
  IonIcon,
  IonSpinner,
} from "@ionic/vue";
import { checkmarkCircle, close } from "ionicons/icons";
import { defineComponent, ref, onMounted } from "vue";

import { openToast } from "../services/toast";

/* New field for figma layout */
import apiTecnici from "../services/tecnici";

export default defineComponent({
  name: "SelectTecnici",
  props: {
    technicians: {
      type: Array,
      required: true,
    },
  },
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonIcon,
    IonSpinner,
  },
  setup(props, context) {
    console.log(props);

    //New field for figma layout
    const tecnici = ref([]);
    const loadingTecnici = ref(false);
    const selectedTecnici = ref([]);

    //selectedTecnici.value = [...props.technicians];

    /**
     *
     * Select and remove tecnico from array to use in intervento creation
     *
     */
    function selectTecnico(tecnico) {
      console.log(selectedTecnici.value);
      if (selectedTecnici.value.includes(tecnico.users_id)) {
        //true, tecnico presente quindi lo rimuovo e nascondo check
        tecnico.selected = false;
        const tecnicoIndex = selectedTecnici.value.indexOf(
          `${tecnico.users_id}`
        );
        if (tecnicoIndex != -1) {
          selectedTecnici.value.splice(tecnicoIndex, 1);
        }
      } else {
        //false, tecnico non presente quindi lo inserisco e mostro check
        tecnico.selected = true;
        selectedTecnici.value.push(tecnico.users_id);
      }
    }

    /**
     *
     * Close modal after select tecnici and send back data to intervento creation
     *
     */
    async function closeModal() {
      if (selectedTecnici.value.length != 0) {
        //console.log("successResponse ", successResponse.value);
        await modalController.dismiss(selectedTecnici);
      } else {
        //console.log("successResponse ", successResponse.value);
        await modalController.dismiss();
      }
    }

    /**
     *
     * Handle close modal by clicking on Chiudi button
     *
     */
    /*     const closeModal = () => {
      modalController.dismiss();
    }; */

    onMounted(() => {
      /* GET TECNICI */
      loadingTecnici.value = true;
      apiTecnici
        .getTecnici()
        .then((response) => {
          //console.log(response);
          tecnici.value = response;
          //Aggiungo campo selected a false, da usare per mostrare il check al click su tecnico
          tecnici.value.forEach((tecnico) => {
            tecnico["selected"] = false;
          });
          // console.log(tecnici.value);
        })
        .catch((error) => {
          error.value = true;
          openToast("Errore durante la richiesta dei tecnici", "danger");
        })
        .finally(() => {
          loadingTecnici.value = false;
          //console.log("fine richiesta tecnici");
        });
    });

    return {
      checkmarkCircle,
      close,
      closeModal,
      //New field for figma layout
      loadingTecnici,
      tecnici,
      selectTecnico,
    };
  },
});
</script>

<style scoped>
/* Rules for figma layout */
.loading_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tecnici_container {
  padding: 16px;
  overflow-y: scroll;
}
.tecnico {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 20px;
}
.closeTecniciModal {
  color: #3f8ae0;
  font-size: 24px;
}

.selected_tecnico_icon {
  color: #3f8ae0;
  font-size: 20px;
}
</style>
